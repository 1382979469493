@charset "UTF-8";
/*
OZ RESET CSS
Author: ONZE
Author URI: http://on-ze.com
Version: 1.1
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
* {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  vertical-align: baseline; }

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%; }

body {
  font-size: 10px;
  font-size: 1.0rem;
  line-height: 1;
  position: relative;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%; }

article, aside, dialog, figure, footer, header, main, menu, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block; }

hr {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

input,
select {
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show; }

img {
  vertical-align: bottom; }

@media screen and (min-width: 737px) and (max-width: 1024px) {
  html {
    zoom: 0.7;
    font-size: 43.75%; } }

body {
  color: #333;
  font-family: "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.0rem;
  text-align: left;
  line-height: 1.8; }

.sp_view {
  display: none !important; }

a {
  color: #124985;
  transition: all 0.3s ease-out; }
  a:hover {
    opacity: .7;
    text-decoration: underline; }

strong {
  color: #cd1818;
  font-weight: bold; }

em {
  font-weight: bold; }

#wrap {
  font-size: 1.4rem; }

/* icon font
----------------------------------------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url("/common/icon/fonts/icomoon.eot?2ubw10");
  src: url("/common/icon/fonts/icomoon.eot?2ubw10#iefix") format("embedded-opentype"), url("/common/icon/fonts/icomoon.woff2?2ubw10") format("woff2"), url("/common/icon/fonts/icomoon.ttf?2ubw10") format("truetype"), url("/common/icon/fonts/icomoon.woff?2ubw10") format("woff"), url("/common/icon/fonts/icomoon.svg?2ubw10#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-freedial .path1:before {
  content: "\e902";
  color: black; }

.icon-freedial .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: black; }

.icon-freedial .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: black; }

.icon-freedial .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: white; }

.icon-envelope:before {
  content: "\e906"; }

.icon-monitor:before {
  content: "\e900"; }

.icon-document:before {
  content: "\e901"; }

.icon-worry:before {
  content: "\e903"; }

.icon-truck:before {
  content: "\e904"; }

.icon-reassurance:before {
  content: "\e905"; }

.icon-th-large:before {
  content: "\f009"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-plus:before {
  content: "\f067"; }

.icon-minus:before {
  content: "\f068"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-shopping-cart:before {
  content: "\f07a"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-caret-left:before {
  content: "\f0d9"; }

.icon-caret-right:before {
  content: "\f0da"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

/* ============================================================ */
/* header */
/* ============================================================ */
header {
  margin: 1px 0 0;
  border-top: 4px solid #3877bd;
  line-height: 1.6; }
  header .inner {
    display: flex;
    align-items: flex-end;
    width: 1000px;
    margin: 0 auto;
    padding: 20px 0; }
    header .inner > div:not(#header_logo) {
      margin: 0 0 0 15px; }
  header #header_logo {
    flex-grow: 1; }
    header #header_logo .tagline {
      color: #000;
      font-size: 1.0rem; }
    header #header_logo p:not(.tagline) {
      margin: 10px 0 0;
      color: #084385;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.0; }
    header #header_logo a:hover {
      text-decoration: none; }
  header #header_nav ul {
    display: flex;
    margin: 15px 0 0;
    font-size: 1.2rem; }
    header #header_nav ul li {
      flex-grow: 1;
      border-right: 1px dotted #b1b1b1;
      text-align: center; }
      header #header_nav ul li:first-child {
        border-left: 1px dotted #b1b1b1; }
      header #header_nav ul li a {
        color: #232323; }
        header #header_nav ul li a:hover {
          color: #2f6eb4;
          text-decoration: none;
          opacity: 1; }
        header #header_nav ul li a i {
          margin: 0 5px 0 0;
          color: #ffa619; }

/* ============================================================ */
/* #global_nav */
/* ============================================================ */
#global_nav {
  border-top: 1px solid #d4d4d4;
  border-bottom: 4px solid #2f6eb4;
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  background-color: #eee; }
  #global_nav ul {
    position: relative;
    display: flex;
    flex-wrap: no-wrap;
    width: 1000px;
    margin: 0 auto; }
    #global_nav ul::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -2px;
      content: "";
      width: 1px;
      border-left: 1px solid #fff; }
    #global_nav ul li {
      flex-basis: 16.6%;
      flex-grow: 1;
      border-right: 1px solid #d4d4d4; }
      #global_nav ul li a {
        display: block;
        background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
        background-color: #eee;
        box-shadow: inset 1px 0 0 white;
        color: #333;
        font-size: 1.5rem;
        text-align: center;
        line-height: 60px; }
        #global_nav ul li a:hover {
          background-color: #dff2fc;
          text-decoration: none;
          opacity: 1; }
      #global_nav ul li:first-child {
        border-left: 1px solid #d4d4d4; }
      #global_nav ul li:last-child {
        position: relative; }
        #global_nav ul li:last-child a {
          background: linear-gradient(to bottom, rgba(237, 63, 63, 0.85) 0%, rgba(237, 63, 63, 0.85) 49%, rgba(221, 43, 43, 0.85) 50%, rgba(205, 24, 24, 0.85) 100%);
          background-color: #ed3f3f;
          box-shadow: none;
          color: #fff; }
          #global_nav ul li:last-child a:hover {
            background-color: #fff; }

/* ============================================================ */
/* #contents */
/* ============================================================ */
#contents {
  width: 1000px;
  margin: 0 auto 100px;
  overflow: hidden; }

#main {
  float: right;
  width: 700px; }

#side {
  float: left;
  width: 260px; }

img {
  max-width: 100%; }

.text_red {
  color: #cd1818; }

hr {
  margin: 20px 0;
  border-top: 1px solid #e2e1e1; }

/* #topic_path
----------------------------------------------------------- */
#topic_path {
  width: 1000px;
  margin: 20px auto;
  font-size: 1.1rem; }
  #topic_path ul {
    display: flex; }
    #topic_path ul li:not(:last-child)::after {
      content: ">";
      margin: 0 5px; }

/* ============================================================ */
/* #main */
/* ============================================================ */
/* ttl
----------------------------------------------------------- */
/*----- .ttl01 -----*/
.ttl01 {
  position: relative;
  display: flex;
  align-items: center;
  height: 120px;
  margin: 0 0 30px;
  padding: 0 35px;
  background: url(/common/img/common/bg_ttl01.png) right bottom no-repeat #fcfafa;
  border: 1px solid #d4d4d4;
  box-shadow: inset 1px 1px 0 white, inset -1px 0 0 white;
  border-bottom: none;
  color: #084385;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.2; }
  .ttl01::before, .ttl01::after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    content: "";
    height: 3px;
    background: #f90; }
  .ttl01::before {
    width: 100%;
    background: #7ba8d9; }
  .ttl01::after {
    width: 250px;
    background: #2f6eb4; }
  .ttl01 small {
    display: block;
    margin: 0 0 5px;
    font-size: 1.8rem; }

/*----- .ttl02 -----*/
.ttl02 {
  position: relative;
  margin: 0 0 15px;
  padding: 3px 0 10px 50px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.2; }
  .ttl02::before {
    position: absolute;
    left: 10px;
    top: 0;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e906";
    color: #2f6eb4;
    font-size: 3rem; }
  .ttl02::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    height: 1px;
    background: #dcdcdc;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05); }

/*----- .ttl03 -----*/
.ttl03 {
  position: relative;
  margin: 0 0 15px;
  padding: 15px 35px;
  background: #f3f9fd;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 2.0rem;
  line-height: 1.4; }
  .ttl03::before {
    position: absolute;
    left: 17px;
    top: 12px;
    bottom: 12px;
    content: "";
    width: 5px;
    background: linear-gradient(to bottom, #3877bd 0%, #1e5ca1 100%);
    border-radius: 2px; }

/*----- .ttl04 -----*/
.ttl04 {
  position: relative;
  margin: 0 0 15px;
  padding: 0 0 10px 28px;
  border-bottom: 1px dotted #dcdcdc;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4; }
  .ttl04::before {
    position: absolute;
    left: 2px;
    top: 3px;
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    border: 5px solid #205ea3;
    border-radius: 50%;
    box-sizing: border-box; }

/* ブロック
--------------------------------------------------------------- */
.row {
  margin: 0 0 50px; }
  .row::after {
    display: block;
    content: "";
    clear: both; }
  .row p:not(:last-child) {
    margin: 0 0 20px; }

/* 左右寄せ／センタリング
--------------------------------------------------------------- */
.al_left {
  text-align: left;
  display: block; }

.al_right {
  text-align: right;
  display: block; }

.al_center {
  text-align: center;
  display: block; }

/* 本文文字サイズ
--------------------------------------------------------------- */
.text_ex_large {
  font-size: 2.0rem; }

.text_large {
  font-size: 1.8rem; }

.text_middle {
  font-size: 1.6rem; }

.text_small {
  font-size: 1.2rem; }

/* リストスタイル
----------------------------------------------------------- */
.list_disc {
  list-style: disc;
  margin: 0 0 0 25px; }
  .list_disc > li:not(:first-child) {
    margin: 10px 0 0; }

.list_note {
  display: table; }
  .list_note > li {
    display: table-row; }
    .list_note > li > *:first-child {
      display: table-cell; }

/* テーブル
--------------------------------------------------------------- */
table {
  width: 100%; }
  table th,
  table td {
    padding: 10px;
    border: 1px solid #dcdcdc;
    vertical-align: middle;
    line-height: 1.6; }
  table th {
    background: #ddedff;
    text-align: center; }

.table th,
.table td {
  padding: 15px 18px;
  text-align: left; }

.table th {
  text-align: left;
  width: 210px; }

.form_table {
  margin: 0 0 25px; }
  .form_table th,
  .form_table td {
    padding: 15px 18px;
    border: none;
    border-bottom: 1px dotted #dcdcdc;
    text-align: left; }
  .form_table th {
    width: 210px;
    background: #2f6eb4;
    color: #fff; }
  .form_table tr:first-child > * {
    border-top: 1px solid #dcdcdc; }
  .form_table tr:last-child > * {
    border-bottom: 1px solid #dcdcdc; }

/* input[type="text"]
----------------------------------------------------------- */
input[type="text"] {
  width: 450px;
  padding: 5px;
  background: #f8f8f8;
  border: 1px solid #dadada; }
  input[type="text"]:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff inset; }
  input[type="text"]:focus {
    background: #fff; }

.has_error input[type="text"] {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error input[type="text"]:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff1f1 inset; }
  .has_error input[type="text"]:focus {
    background: #fff; }

/* textarea
----------------------------------------------------------- */
textarea {
  width: 450px;
  height: 200px;
  padding: 5px;
  background: #f8f8f8;
  border: 1px solid #dadada; }
  textarea:focus {
    background: #fff; }

.has_error textarea {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error textarea:focus {
    background: #fff; }

/* select
----------------------------------------------------------- */
select {
  padding: 5px;
  border: 1px solid #dadada; }

.has_error select {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error select:focus {
    background: #fff; }

/* .checkbox
----------------------------------------------------------- */
label.checkbox {
  position: relative;
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 0 0 0 25px;
  overflow: hidden;
  cursor: pointer; }
  label.checkbox::before {
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #dcdcdc;
    z-index: 3; }
  label.checkbox::after {
    position: absolute;
    top: 45%;
    left: 5px;
    display: block;
    content: "";
    width: 6px;
    height: 10px;
    margin-top: -8px;
    border-right: 3px solid #2f6eb4;
    border-bottom: 3px solid #2f6eb4;
    transform: rotate(45deg);
    z-index: 1; }
  label.checkbox input[type="checkbox"] {
    position: absolute;
    top: 4px;
    left: -40px;
    display: block;
    width: 16px;
    height: 16px;
    box-shadow: 41px 0 0 #fff;
    z-index: 2;
    appearance: none; }
    label.checkbox input[type="checkbox"]:checked {
      box-shadow: none; }

/* .radio
----------------------------------------------------------- */
.radio {
  position: relative;
  display: block;
  margin: 0 0 5px;
  padding: 0 0 0 25px;
  overflow: hidden;
  cursor: pointer; }
  .radio:before {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 3;
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #b2b2b2;
    border-radius: 50%; }
  .radio:after {
    position: absolute;
    top: 4px;
    left: 3px;
    z-index: 1;
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #2f6eb4; }
  .radio input[type="radio"] {
    appearance: none;
    position: absolute;
    top: 0;
    left: -23px;
    z-index: 2;
    width: 20px;
    height: 20px;
    box-shadow: 20px -1px #fff; }
    .radio input[type="radio"]:checked {
      box-shadow: none; }
    .radio input[type="radio"]:focus {
      opacity: .2;
      box-shadow: 20px -1px #fff; }

/* .alert_block
----------------------------------------------------------- */
.alert_block {
  margin: 20px 0;
  padding: 20px 25px;
  background: #fffafa;
  border: 5px solid #ffc7c7; }

/* button
----------------------------------------------------------- */
[class^="btn"] i {
  margin: 0 5px 0 0; }

[class^="btn"]:hover {
  opacity: 1;
  text-decoration: none; }

.btn {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 6px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.45) 49%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 100%);
  background-color: #ededed;
  color: #333; }
  .btn i {
    color: #fd6601; }
  .btn:hover {
    background-color: #fef1bc; }

.btn_blue {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 6px 20px;
  border: 1px solid #125290;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to bottom, rgba(65, 133, 199, 0.8) 0%, rgba(65, 133, 199, 0.8) 49%, rgba(44, 114, 181, 0.8) 50%, rgba(26, 96, 164, 0.8) 100%);
  background-color: #1a5fa3;
  color: #fff; }
  .btn_blue i {
    color: #fff; }
  .btn_blue:hover {
    background-color: #000; }

.btn_red {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 6px 20px;
  border: 1px solid #b71313;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to bottom, rgba(237, 63, 63, 0.8) 0%, rgba(237, 63, 63, 0.8) 49%, rgba(221, 43, 43, 0.8) 50%, rgba(205, 24, 24, 0.8) 100%);
  background-color: #cd1818;
  color: #fff; }
  .btn_red i {
    color: #fff; }
  .btn_red:hover {
    background-color: #600000; }

.btn_submit {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 6px 20px;
  border: 1px solid #dd630d;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to bottom, rgba(255, 122, 28, 0.8) 0%, rgba(255, 122, 28, 0.8) 49%, rgba(253, 112, 12, 0.8) 50%, rgba(253, 112, 12, 0.8) 100%);
  background-color: #fd700c;
  color: #fff; }
  .btn_submit i {
    color: #fff; }
  .btn_submit:hover {
    background-color: #ce5c0c; }

/* .anchor_link
----------------------------------------------------------- */
.anchor_link {
  margin: 0 0 40px; }
  .anchor_link ul {
    display: flex;
    line-height: 1.4; }
    .anchor_link ul li {
      flex: 1; }
      .anchor_link ul li a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
        background-color: #eee;
        border: 1px solid #dcdcdc;
        border-left: none;
        box-shadow: inset 1px 0 0 white;
        color: #333;
        font-size: 1.8rem; }
        .anchor_link ul li a i {
          position: absolute;
          left: 50%;
          bottom: 0;
          margin: 0 0 0 -3px;
          color: #2f6eb4;
          font-size: 1.2rem;
          line-height: 1; }
        .anchor_link ul li a:hover {
          background-color: #dff2fc;
          text-decoration: none;
          opacity: 1; }
      .anchor_link ul li:first-child a {
        border-left: 1px solid #dcdcdc;
        border-radius: 5px 0 0 5px; }
      .anchor_link ul li:last-child a {
        border-radius: 0 5px 5px 0; }

/* .price_list
----------------------------------------------------------- */
.price_list .box {
  margin: 20px 0 30px;
  padding: 10px 15px;
  background: #fffafa;
  border: 3px solid #ffc7c7; }

.price_list table {
  margin: 0 0 10px;
  font-size: 1.2rem; }
  .price_list table thead th:nth-child(1) {
    width: 110px; }
  .price_list table thead th:nth-child(2) {
    width: 115px; }
  .price_list table thead th:nth-child(3) {
    width: 155px; }
  .price_list table thead th:nth-child(4) {
    width: 170px; }
  .price_list table thead th:nth-child(5) {
    width: 150px; }
  .price_list table tbody th {
    background: #f6f6f6; }
  .price_list table tbody td {
    text-align: center; }
    .price_list table tbody td.total {
      color: #00358e;
      font-weight: bold;
      font-size: 1.4rem; }

.price_list .cartjs_product_table {
  display: none; }

.price_list .cartjs_cart_in {
  position: relative;
  transition: all 0.3s ease-out; }
  .price_list .cartjs_cart_in::before {
    position: absolute;
    top: 50%;
    left: 8px;
    margin-top: -0.5em;
    content: "\f07a";
    color: #fd6601;
    font-family: 'icomoon' !important;
    font-size: 1.5rem;
    line-height: 1; }
  .price_list .cartjs_cart_in input[type="submit"] {
    display: block;
    width: 100%;
    padding: 5px 0 5px 1em;
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.45) 49%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 100%);
    background-color: #ededed;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    cursor: pointer; }
    .price_list .cartjs_cart_in input[type="submit"]:hover {
      background-color: #fef1bc; }

.price_list .note {
  display: table;
  margin: 20px 0 0; }
  .price_list .note li {
    display: table-row; }
    .price_list .note li > span:first-child {
      display: table-cell;
      padding: 0 1em 0 0; }

/* .feature_block
----------------------------------------------------------- */
.feature_block {
  padding: 40px 35px;
  background: url(/common/img/common/bg_texture03.png) 0 0 repeat; }
  .feature_block > * {
    background: #fff; }
  .feature_block > *:not(:last-child) {
    margin: 0 0 30px; }
  .feature_block .lead {
    position: relative;
    min-height: 375px;
    padding: 25px; }
    .feature_block .lead::before {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 5;
      display: block;
      content: "";
      width: 238px;
      height: 318px;
      background: url(/common/img/common/pic_feature01.png) 0 0 no-repeat; }
    .feature_block .lead h2 {
      position: absolute;
      top: -40px;
      left: -35px;
      z-index: 1; }
    .feature_block .lead p {
      margin: 0 0 0 230px;
      line-height: 1.6; }
      .feature_block .lead p.lead01 {
        margin: 0 0 0 175px;
        color: #2f6eb4;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.4; }
      .feature_block .lead p.lead02 {
        margin: 10px 0 20px 175px;
        padding: 10px 0;
        border-top: 1px solid #2f6eb4;
        border-bottom: 1px solid #2f6eb4;
        color: #2f6eb4;
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.4; }
  .feature_block section div {
    display: flex;
    background: #2167aa; }
    .feature_block section div h3 {
      flex-grow: 1;
      align-self: center;
      padding: 0 0 0 20px;
      color: #fff;
      font-size: 2.4rem;
      line-height: 1.4; }
  .feature_block section p {
    padding: 25px 30px; }
  .feature_block section.transparency div {
    position: relative; }
    .feature_block section.transparency div::before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      content: "";
      width: 412px;
      height: 187px;
      background: url(/common/img/common/pic_feature03.png) 0 0 no-repeat; }
  .feature_block section.transparency p {
    padding-top: 50px; }

/* .pager
----------------------------------------------------------- */
.pager {
  margin: 35px 0 50px; }
  .pager ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pager ul li {
      margin: 0 3px 10px; }
      .pager ul li > * {
        display: block;
        padding: 8px 12px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        text-decoration: none;
        white-space: nowrap;
        line-height: 1.4;
        cursor: pointer; }
        .pager ul li > *:hover, .pager ul li > *.current {
          background: #2f6eb4;
          border: 1px solid #2f6eb4;
          color: #fff;
          opacity: 1; }

/* .entry_pager（詳細ページ用）
----------------------------------------------------------- */
.entry_pager {
  position: relative;
  margin: 50px 0;
  padding: 20px 0 0;
  border-top: 1px dotted #dcdcdc; }
  .entry_pager li {
    text-align: center; }
    .entry_pager li.prev, .entry_pager li.next {
      position: absolute;
      top: 20px;
      left: 0; }
    .entry_pager li.next {
      left: inherit;
      right: 0; }
    .entry_pager li a {
      display: inline-block;
      padding: 8px 15px;
      background: #2f6eb4;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      white-space: nowrap; }

/* .img_box
----------------------------------------------------------- */
.img_box {
  margin: 0 0 20px; }
  .img_box::after {
    display: block;
    content: "";
    clear: both; }
  .img_box .floatL {
    float: left;
    margin: 0 20px 0 0; }
  .img_box .floatR {
    float: right;
    margin: 0 0 0 20px; }

/* ============================================================ */
/* side */
/* ============================================================ */
/* #side_pickup
----------------------------------------------------------- */
#side_pickup {
  margin: 0 0 20px;
  padding: 10px;
  background: url(/common/img/common/bg_texture02.png) 0 0 repeat; }
  #side_pickup section {
    padding: 1px;
    background: #fff; }
    #side_pickup section [class^="btn"] {
      display: block;
      font-size: 1.5rem; }
    #side_pickup section:not(:first-child) {
      margin: 10px 0 0 0; }
    #side_pickup section:not(.shipping_day):not(.price_list) {
      padding-bottom: 20px; }
      #side_pickup section:not(.shipping_day):not(.price_list) h2 {
        position: relative;
        margin: 0 0 15px;
        padding: 10px;
        background: #afd3fb;
        color: #124985;
        font-size: 1.5rem;
        font-weight: bold; }
        #side_pickup section:not(.shipping_day):not(.price_list) h2::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          content: "";
          border-bottom: 1px dotted #a0b187; }
      #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) {
        overflow: hidden;
        margin: 0 14px 10px; }
        #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span {
          position: relative;
          display: block;
          float: left;
          width: 60px;
          height: 60px;
          margin: 0 15px 0 0;
          border: 3px solid #d8eaff;
          border-radius: 3px;
          text-align: center; }
          #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            border: 1px solid #afd3fb; }
          #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span i {
            color: #134884;
            font-size: 4.0rem;
            line-height: 54px; }
        #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) p {
          font-size: 1.3rem; }
      #side_pickup section:not(.shipping_day):not(.price_list) div {
        margin: 0 14px; }
        #side_pickup section:not(.shipping_day):not(.price_list) div [class^="btn"] {
          padding: 6px 0; }
    #side_pickup section.shipping_day, #side_pickup section.price_list {
      padding-top: 6px; }
      #side_pickup section.shipping_day h2, #side_pickup section.price_list h2 {
        padding: 5px 10px;
        background: #2f6eb4;
        color: #fff;
        font-size: 1.9rem;
        font-weight: bold;
        line-height: 1.4; }
      #side_pickup section.shipping_day div, #side_pickup section.price_list div {
        padding: 15px 14px;
        border-top: 1px solid #e0e0e0;
        background: url(/common/img/common/bg_texture01.png) 0 0 repeat; }
        #side_pickup section.shipping_day div [class^="btn"], #side_pickup section.price_list div [class^="btn"] {
          padding: 12px 0; }
    #side_pickup section.shipping_day h2 {
      font-size: 2.4rem; }
    #side_pickup section.shipping_day h3 {
      font-size: 1.4rem;
      text-align: center;
      background: #F3F0D0;
      padding: 5px 0;
      font-weight: bold; }
    #side_pickup section.shipping_day div p {
      margin: 0 0 10px; }
    #side_pickup section.shipping_day #view_cart {
      margin-top: 10px;
      display: block; }
      #side_pickup section.shipping_day #view_cart .cartjs_box {
        display: none; }

@media screen and (min-width: 737px) and (max-width: 768px) {
  #side_pickup section:not(.shipping_day):not(.price_list) h2 {
    font-size: 1.4rem; }
  #side_pickup section.shipping_day h2 {
    font-size: 2.3rem; } }

/* #side_column / #side_voice
----------------------------------------------------------- */
#side_column,
#side_voice {
  margin: 0 0 20px;
  border: 1px solid #dcdcdc;
  border-top: 4px solid #2f6eb4; }
  #side_column h2,
  #side_voice h2 {
    padding: 10px 14px;
    background: linear-gradient(to bottom, #fefffe 0%, #edeeed 100%);
    border-bottom: 1px solid #dcdcdc;
    color: #124985;
    font-size: 1.7rem;
    line-height: 1.4; }
  #side_column ul li,
  #side_voice ul li {
    border-bottom: 1px dotted #dcdcdc;
    line-height: 1.6; }
    #side_column ul li a,
    #side_voice ul li a {
      display: block;
      padding: 15px; }

/* #side_column */
#side_column ul li {
  font-size: 1.2rem; }
  #side_column ul li:nth-child(2n) {
    background: #e6f1fe; }
  #side_column ul li a {
    padding: 10px 15px; }
    #side_column ul li a:hover {
      text-decoration: none; }
      #side_column ul li a:hover p {
        text-decoration: underline; }
    #side_column ul li a time {
      color: #333;
      font-weight: bold; }
    #side_column ul li a p {
      position: relative;
      padding: 0 0 0 10px; }
      #side_column ul li a p i {
        position: absolute;
        top: 3px;
        left: 2px;
        color: #ffa619; }

#side_column .al_center {
  padding: 15px 0; }

/* #side_voice */
#side_voice ul li:last-child {
  border-bottom: none; }

#side_voice ul li a {
  overflow: hidden; }
  #side_voice ul li a:hover {
    text-decoration: none; }
    #side_voice ul li a:hover p:first-of-type {
      text-decoration: underline; }
  #side_voice ul li a img {
    float: left; }
  #side_voice ul li a p {
    margin: 0 0 0 85px; }
    #side_voice ul li a p:first-of-type {
      position: relative;
      height: 45px;
      padding: 0 0 0 10px;
      font-weight: bold;
      overflow: hidden; }
      #side_voice ul li a p:first-of-type::before, #side_voice ul li a p:first-of-type::after {
        position: absolute;
        background: #fff; }
      #side_voice ul li a p:first-of-type::before {
        content: "･･･";
        bottom: 0;
        right: 0;
        font-weight: normal; }
      #side_voice ul li a p:first-of-type::after {
        content: "";
        width: 100%;
        height: 100%; }
      #side_voice ul li a p:first-of-type i {
        position: absolute;
        top: 2px;
        left: 0; }
    #side_voice ul li a p:last-of-type {
      color: #000;
      font-size: 1.1rem;
      text-align: right; }

/* #side_postage
----------------------------------------------------------- */
#side_postage h2 {
  position: relative;
  margin: 0 0 7px;
  padding: 10px 20px;
  background: #2f6eb4;
  color: #fff;
  font-weight: bold; }
  #side_postage h2::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    content: "";
    border-bottom: 1px dotted #a0b187; }

#side_postage table {
  margin: 0 0 10px;
  font-size: 1.1rem; }
  #side_postage table th,
  #side_postage table td {
    padding: 5px 8px; }
  #side_postage table th {
    white-space: nowrap; }

#side_postage p {
  font-size: 1.1rem; }
  #side_postage p strong {
    font-size: 1.2rem; }

/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
  background: url(/common/img/common/bg_footer.png) 0 0 repeat; }
  footer::before {
    display: block;
    content: "";
    width: 100%;
    height: 45px;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-top: 1px solid #cbcaca;
    border-bottom: 1px solid #cbcaca; }
  footer #footer_nav {
    width: 1000px;
    margin: 40px auto 60px; }
    footer #footer_nav h2 {
      margin: 0 0 20px;
      font-size: 1.6rem;
      font-weight: bold;
      overflow: hidden; }
      footer #footer_nav h2 span {
        position: relative;
        display: inline-block;
        padding: 0 20px 0 0; }
        footer #footer_nav h2 span::after {
          position: absolute;
          top: 50%;
          left: 100%;
          content: '';
          width: 1000%;
          margin: -2px 0 0;
          border-top: 3px double #c6c6c6; }
    footer #footer_nav div {
      position: relative; }
      footer #footer_nav div::before, footer #footer_nav div::after {
        position: absolute;
        top: 0;
        bottom: 0;
        content: "";
        border-left: 1px solid rgba(255, 255, 255, 0.5); }
      footer #footer_nav div::before {
        left: 240px; }
      footer #footer_nav div::after {
        left: 500px; }
      footer #footer_nav div ul {
        columns: 220px 4;
        column-gap: 40px;
        font-size: 1.2rem; }
        footer #footer_nav div ul::after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 760px;
          content: "";
          border-left: 1px solid rgba(255, 255, 255, 0.5); }
        footer #footer_nav div ul li {
          position: relative;
          margin: 0 0 3px;
          padding: 0 0 0 14px; }
          footer #footer_nav div ul li a {
            color: #333; }
            footer #footer_nav div ul li a:hover {
              color: #2f6eb4; }
            footer #footer_nav div ul li a i {
              position: absolute;
              top: 4px;
              left: 0;
              color: #2f6eb4; }
  footer .pagetop {
    display: none;
    position: fixed;
    right: 50px;
    bottom: 50px; }
    footer .pagetop.show {
      display: block; }
    footer .pagetop a {
      display: block;
      width: 50px;
      height: 50px;
      background: rgba(47, 110, 180, 0.8);
      border-radius: 4px;
      color: #fff;
      font-size: 2.0rem;
      text-align: center;
      line-height: 50px; }
      footer .pagetop a:hover {
        text-decoration: none; }
  footer .copyright {
    padding: 20px 0;
    background: #e2f0ff;
    font-size: 1.2rem;
    text-align: center; }

/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
  /* #visual
	----------------------------------------------------------- */
  /* #stock
	----------------------------------------------------------- */
  /* #feature
	----------------------------------------------------------- */
  /* #inquiry
	----------------------------------------------------------- */
  /* #reassurance
	----------------------------------------------------------- */
  /* #order
	----------------------------------------------------------- */
  /* #worry
	----------------------------------------------------------- */ }
  #index .index_ttl {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    font-size: 2.6rem;
    font-weight: bold; }
    #index .index_ttl i {
      margin: 0 10px 0 0;
      color: #2f6eb4;
      font-size: 3.6rem; }
  #index #visual {
    width: 1000px;
    margin: 30px auto 20px; }
  #index #stock {
    position: relative;
    margin: 0 0 30px; }
    #index #stock a {
      position: absolute;
      left: 28px;
      display: block; }
      #index #stock a:nth-of-type(1) {
        top: 156px; }
      #index #stock a:nth-of-type(2) {
        top: 236px; }
  #index #feature {
    margin: 0 0 30px; }
  #index #inquiry {
    position: relative; }
    #index #inquiry .btn_red {
      position: absolute;
      top: 201px;
      left: 333px;
      width: 230px;
      height: 55px;
      padding: 0;
      font-size: 1.7rem;
      line-height: 55px; }
  #index #reassurance > p {
    margin: 0 0 30px; }
  #index #reassurance .column {
    display: flex;
    justify-content: space-between; }
    #index #reassurance .column div {
      width: 340px;
      overflow: hidden; }
      #index #reassurance .column div img {
        float: right;
        margin: 0 0 0 20px; }
      #index #reassurance .column div p {
        margin: 0 0 10px; }
        #index #reassurance .column div p strong,
        #index #reassurance .column div p em {
          font-size: 1.5rem; }
  #index #order .ttl03 {
    margin-top: 25px; }
  #index #worry ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 25px 0 0; }
    #index #worry ul li {
      width: 50%;
      padding: 25px 0;
      border-top: 1px dotted #b1b1b1;
      box-sizing: border-box;
      line-height: 1.6; }
      #index #worry ul li:nth-child(2n+1) {
        padding-right: 10px;
        border-right: 1px dotted #b1b1b1; }
      #index #worry ul li:nth-child(2n) {
        padding-left: 20px; }
      #index #worry ul li:nth-child(-n+2) {
        padding-top: 0;
        border-top: none; }
      #index #worry ul li:nth-last-child(-n+2) {
        padding-bottom: 0; }
      #index #worry ul li a {
        display: block; }
        #index #worry ul li a:hover {
          text-decoration: none; }
          #index #worry ul li a:hover h3 {
            text-decoration: underline; }
        #index #worry ul li a img {
          float: left;
          margin: 0 15px 0 0; }
        #index #worry ul li a h3 {
          position: relative;
          margin: 0 0 5px;
          padding: 0 0 0 14px;
          font-size: 1.6rem;
          font-weight: bold;
          overflow: hidden; }
          #index #worry ul li a h3 i {
            position: absolute;
            top: 3px;
            left: 1px; }
        #index #worry ul li a p {
          color: #333;
          overflow: hidden; }

/* ============================================================ */
/* /product/ */
/* ============================================================ */
#product_index {
  /* #difference
	----------------------------------------------------------- */
  /* #cheap
	----------------------------------------------------------- */ }
  #product_index #difference div,
  #product_index #cheap div {
    padding: 40px 35px;
    background: url(/common/img/common/bg_texture03.png) 0 0 repeat; }
    #product_index #difference div h3,
    #product_index #cheap div h3 {
      position: relative;
      padding: 15px 0;
      background: #fff;
      color: #2f6eb4;
      font-size: 3.0rem;
      font-weight: bold;
      text-align: center; }
      #product_index #difference div h3::before,
      #product_index #cheap div h3::before {
        position: absolute;
        top: 50%;
        left: -35px;
        display: block;
        content: "";
        width: 154px;
        height: 154px;
        margin: -82px 0 0;
        background: url(/common/img/product/ttl_different.png) 0 0 no-repeat; }
    #product_index #difference div ol,
    #product_index #cheap div ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #product_index #difference div ol li,
      #product_index #cheap div ol li {
        position: relative;
        margin: 45px 0 0 0;
        padding: 10px;
        background: #fff;
        counter-increment: number; }
        #product_index #difference div ol li::before,
        #product_index #cheap div ol li::before {
          position: absolute;
          top: -25px;
          left: 50%;
          z-index: 1;
          margin: 0 0 0 -25px;
          display: block;
          content: counter(number);
          width: 50px;
          height: 50px;
          background: #fff;
          border: 4px solid #2f6eb4;
          border-radius: 50%;
          box-sizing: border-box;
          color: #2f6eb4;
          font-size: 2.2rem;
          font-weight: bold;
          text-align: center;
          line-height: 42px; }
        #product_index #difference div ol li h4,
        #product_index #cheap div ol li h4 {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 5;
          height: 85px;
          margin: 0 0 20px;
          background: #2f6eb4;
          color: #fff;
          font-size: 2.0rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.4; }
          #product_index #difference div ol li h4::after,
          #product_index #cheap div ol li h4::after {
            position: absolute;
            left: 50%;
            bottom: -12px;
            content: "";
            margin: 0 0 0 -12px;
            border-color: #2f6eb4 transparent transparent transparent;
            border-style: solid;
            border-width: 12px 12px 0; }
          #product_index #difference div ol li h4 span,
          #product_index #cheap div ol li h4 span {
            display: block;
            width: 100%; }
  #product_index #difference div ol li {
    width: 200px; }
    #product_index #difference div ol li:nth-child(5) {
      width: 415px; }
    #product_index #difference div ol li:nth-child(-n+3) h4 {
      height: 115px; }
  #product_index #cheap div ol li {
    width: 307px; }
    #product_index #cheap div ol li:first-child {
      width: 100%;
      background: url(/common/img/product/bg_reason01.png) right bottom no-repeat #fff; }
      #product_index #cheap div ol li:first-child p:last-child {
        margin-right: 240px; }
    #product_index #cheap div ol li:nth-child(2) {
      background: url(/common/img/product/bg_reason02.png) right bottom no-repeat #fff; }
    #product_index #cheap div ol li:nth-child(3) {
      background: url(/common/img/product/bg_reason03.png) right bottom no-repeat #fff; }
    #product_index #cheap div ol li strong {
      color: #2f6eb4;
      font-size: 1.6rem; }
    #product_index #cheap div ol li em {
      color: #2f6eb4; }

/* ============================================================ */
/* /result/ */
/* ============================================================ */
#result_index {
  /* #trading_performance
	----------------------------------------------------------- */
  /* #counterparty
	----------------------------------------------------------- */
  /* #voice
	----------------------------------------------------------- */ }
  #result_index #trading_performance .trading_performance_list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    line-height: 1.6; }
    #result_index #trading_performance .trading_performance_list::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      border-bottom: 1px dotted #dcdcdc; }
    #result_index #trading_performance .trading_performance_list li {
      position: relative;
      width: 34%;
      padding: 10px 0 10px 20px;
      border-bottom: 1px dotted #dcdcdc; }
      #result_index #trading_performance .trading_performance_list li:nth-child(3n) {
        width: 32%; }
      #result_index #trading_performance .trading_performance_list li::before {
        position: absolute;
        top: 18px;
        left: 10px;
        display: block;
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #2f6eb4; }
  #result_index #counterparty ul {
    display: flex;
    flex-wrap: wrap; }
    #result_index #counterparty ul li {
      position: relative;
      width: 50%;
      padding: 10px 0 10px 27px; }
      #result_index #counterparty ul li:nth-child(2n) {
        border-left: 1px solid #fff; }
      #result_index #counterparty ul li:nth-child(4n+1), #result_index #counterparty ul li:nth-child(4n+2) {
        background: #fafafa; }
      #result_index #counterparty ul li::before {
        position: absolute;
        top: 20px;
        left: 18px;
        display: block;
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #2f6eb4; }
  #result_index #voice ul li {
    padding: 30px 0;
    border-bottom: 1px dotted #dcdcdc;
    overflow: hidden; }
    #result_index #voice ul li:last-child {
      border-bottom: none; }
    #result_index #voice ul li img {
      float: left; }
    #result_index #voice ul li p {
      margin: 0 0 0 380px; }
      #result_index #voice ul li p.customer {
        margin-top: 25px;
        text-align: right; }

/* ============================================================ */
/* /faq/ */
/* ============================================================ */
#faq_index .row dl dt {
  position: relative;
  margin: 15px 0 0;
  padding: 15px 20px;
  background: #f3f9fd;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 1.6rem;
  line-height: 1.4;
  cursor: pointer; }
  #faq_index .row dl dt i {
    position: absolute;
    top: 50%;
    right: 15px;
    margin: -0.5em 0 0;
    color: #2f6eb4;
    font-size: 1.3rem;
    line-height: 1;
    transition: all 0.3s ease-out; }
  #faq_index .row dl dt::before {
    content: "Q";
    margin: 0 10px 0 0;
    color: #2f6eb4;
    font-size: 1.6rem;
    font-weight: bold; }
  #faq_index .row dl dt.active {
    border-radius: 5px 5px 0 0; }
    #faq_index .row dl dt.active i {
      transform: rotate(180deg); }

#faq_index .row dl dd {
  display: none;
  position: relative;
  margin: 0 0 25px;
  padding: 20px 20px 20px 50px;
  border: 1px solid #dcdcdc;
  border-top: none;
  border-radius: 0 0 5px 5px; }
  #faq_index .row dl dd::before {
    position: absolute;
    top: 22px;
    left: 18px;
    content: "A";
    margin: 0 10px 0 0;
    color: #f77010;
    font-size: 2.0rem;
    font-weight: bold;
    line-height: 1; }
  #faq_index .row dl dd h3 {
    margin: 0 0 5px;
    color: #2f6eb4;
    font-size: 1.5rem;
    font-weight: bold; }

/* ============================================================ */
/* /reassure/ */
/* ============================================================ */
#reassure_index {
  /* #purchase
	----------------------------------------------------------- */
  /* #order
	----------------------------------------------------------- */ }
  #reassure_index #purchase img {
    float: right;
    margin: 0 0 0 20px; }
  #reassure_index #purchase strong {
    font-size: 1.6rem; }
  #reassure_index #order {
    /*----- #lead -----*/
    /*----- #tel -----*/
    /*----- #web -----*/ }
    #reassure_index #order #lead em {
      font-size: 1.6rem; }
      #reassure_index #order #lead em span {
        color: #2f6eb4; }
    #reassure_index #order #tel ol li {
      position: relative;
      padding: 15px 15px 15px 55px;
      counter-increment: flow;
      border: 1px solid #dcdcdc;
      border-radius: 5px; }
      #reassure_index #order #tel ol li::before {
        position: absolute;
        top: 15px;
        left: 15px;
        display: block;
        content: counter(flow);
        width: 25px;
        height: 25px;
        background: #2f6eb4;
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
        line-height: 25px; }
      #reassure_index #order #tel ol li:not(:last-child) {
        margin: 0 0 52px; }
        #reassure_index #order #tel ol li:not(:last-child)::after {
          position: absolute;
          left: 50%;
          bottom: -38px;
          content: "";
          margin: 0 0 0 -20px;
          border-color: #dbdbdb transparent transparent;
          border-style: solid;
          border-width: 22px 20px 0; }
      #reassure_index #order #tel ol li strong,
      #reassure_index #order #tel ol li em {
        font-size: 1.8rem; }
    #reassure_index #order #web img {
      float: left;
      margin: 0 25px 0 0; }
    #reassure_index #order #web .btn_red {
      width: 480px;
      height: 70px;
      padding: 0;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 70px; }
      #reassure_index #order #web .btn_red i {
        margin: 0 10px 0 0;
        font-size: 1.4rem;
        font-weight: normal; }

/* ============================================================ */
/* /sample/ */
/* ============================================================ */
.sample .row .alert_block {
  margin: 0 0 30px; }

.sample .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.sample .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.sample .row .al_center {
  margin: 30px 0; }
  .sample .row .al_center .btn,
  .sample .row .al_center .btn_submit {
    width: 230px;
    height: 60px;
    margin: 0 5px;
    padding: 0;
    font-size: 1.6rem;
    line-height: 60px; }

/* ============================================================ */
/* #sample_index */
/* ============================================================ */
#sample_index input[name="zip"] {
  width: 150px; }

#sample_index input[name="name"],
#sample_index input[name="tel"],
#sample_index input[name="fax"] {
  width: 300px; }

#sample_index .triggered p {
  margin: 0 0 25px 25px; }
  #sample_index .triggered p:last-child {
    margin-bottom: 0; }

#sample_index .triggered input[type="text"] {
  width: 300px; }

/* ============================================================ */
/* /order/ */
/* ============================================================ */
.order .row .alert_block {
  margin: 0 0 30px; }

.order .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.order .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.order .row .al_center {
  margin: 30px 0; }
  .order .row .al_center .btn,
  .order .row .al_center .btn_submit {
    width: 230px;
    height: 60px;
    margin: 0 5px;
    padding: 0;
    font-size: 1.6rem;
    line-height: 60px; }

/* ============================================================ */
/* #order_index */
/* ============================================================ */
#order_index input[name="zip"] {
  width: 150px; }

#order_index input[name="name"],
#order_index input[name="tel"],
#order_index input[name="fax"] {
  width: 300px; }

#order_index .radio {
  display: inline-block;
  margin-right: 8px; }

#order_index .note {
  margin: 10px 0 0; }

#order_index .attention {
  height: 250px;
  margin: 0 0 20px;
  padding: 15px;
  border: 1px solid #dcdcdc;
  overflow: auto; }

#order_index #postage table {
  margin: 0 0 10px; }
  #order_index #postage table tbody th {
    background: #f6f6f6; }
  #order_index #postage table tbody td {
    text-align: center; }

#order_index #postage .alert_block p {
  font-size: 1.8rem; }
  #order_index #postage .alert_block p span {
    color: #cd1818; }

/* ============================================================ */
/* /inquiry/ */
/* ============================================================ */
.inquiry .row .alert_block {
  margin: 0 0 30px; }

.inquiry .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.inquiry .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.inquiry .row .al_center {
  margin: 30px 0; }
  .inquiry .row .al_center .btn,
  .inquiry .row .al_center .btn_submit {
    width: 230px;
    height: 60px;
    margin: 0 5px;
    padding: 0;
    font-size: 1.6rem;
    line-height: 60px; }

/* ============================================================ */
/* #inquiry_index */
/* ============================================================ */
#inquiry_index input[name="zip"] {
  width: 150px; }

#inquiry_index input[name="name"],
#inquiry_index input[name="tel"],
#inquiry_index input[name="fax"] {
  width: 300px; }

/* ============================================================ */
/* #column_index */
/* ============================================================ */
#column_index .column_list li {
  padding: 10px 15px;
  border-bottom: 1px dotted #dcdcdc; }
  #column_index .column_list li:nth-child(even) {
    background: #e6f1fe; }
  #column_index .column_list li a {
    display: flex;
    text-decoration: none; }
    #column_index .column_list li a time {
      color: #333; }
    #column_index .column_list li a p {
      position: relative;
      margin: 0 0 0 20px;
      padding: 0 0 0 12px; }
      #column_index .column_list li a p i {
        position: absolute;
        top: 4px;
        left: 0;
        color: #ffa619; }
    #column_index .column_list li a:hover p {
      text-decoration: underline; }

/* ============================================================ */
/* #column_detail */
/* ============================================================ */
#column_detail .entry_body time {
  font-weight: bold; }

#column_detail .entry_body .entry_block {
  margin: 20px 0 0; }
  #column_detail .entry_body .entry_block > *:not(.row) {
    margin: 0 0 15px; }
  #column_detail .entry_body .entry_block strong {
    color: #333; }
  #column_detail .entry_body .entry_block table .al_center {
    display: table-cell; }
  #column_detail .entry_body .entry_block table tbody th {
    background: #f6f6f6; }
  #column_detail .entry_body .entry_block .text_b {
    font-weight: bold; }
  #column_detail .entry_body .entry_block #test th.a4 {
    background: #dcedc9; }
  #column_detail .entry_body .entry_block #test td.a4 {
    background: #eaf4de; }
  #column_detail .entry_body .entry_block #test th.kaku2 {
    background: #ffecb4; }
  #column_detail .entry_body .entry_block #test td.kaku2 {
    background: #fff4d1; }
  #column_detail .entry_body .entry_block #test .reaction tbody th {
    width: 24%; }
  #column_detail .entry_body .entry_block #test .reaction tbody td {
    width: 38%; }
  #column_detail .entry_body .entry_block #test .box {
    padding: 15px 0;
    background: #e6f1fe;
    border: 2px solid #ddedff;
    text-align: center; }
    #column_detail .entry_body .entry_block #test .box strong {
      color: #084385; }
  #column_detail .entry_body .entry_block #test .result {
    padding: 20px;
    background: #e6f1fe;
    border-radius: 4px; }
    #column_detail .entry_body .entry_block #test .result .flex {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px; }
      #column_detail .entry_body .entry_block #test .result .flex div {
        width: 49%;
        padding: 15px;
        background: #fff;
        border-radius: 4px; }
    #column_detail .entry_body .entry_block #test .result > table th {
      width: 20%; }
    #column_detail .entry_body .entry_block #test .result > table td {
      background: #fff; }
  #column_detail .entry_body .entry_block #test .comment {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 0 20px; }
    #column_detail .entry_body .entry_block #test .comment div {
      position: relative;
      margin: 0 20px 0 0;
      padding: 15px 20px;
      background: #fafafa;
      border-radius: 4px;
      flex: 1; }
      #column_detail .entry_body .entry_block #test .comment div::after {
        position: absolute;
        top: 50%;
        right: -12px;
        z-index: 5;
        content: "";
        margin: -5px 0 0;
        border-color: transparent transparent transparent #fafafa;
        border-style: solid;
        border-width: 10px 0 10px 12px; }
  #column_detail .entry_body .entry_block #size table tbody th {
    width: 22%; }
  #column_detail .entry_body .entry_block #size table tbody td {
    width: 16%; }
  #column_detail .entry_body .entry_block #size table th.dmft {
    background: #ffecb4; }
  #column_detail .entry_body .entry_block #size table td.dmft {
    width: 30%;
    background: #fff4d1; }
  #column_detail .entry_body .entry_block #size .img_box {
    display: flex;
    align-items: center; }
    #column_detail .entry_body .entry_block #size .img_box div {
      flex: 1;
      margin: 0 0 0 20px; }
